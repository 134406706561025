import axios from 'axios';

import { logInfoOrError } from 'utils/logInfoOrError';

import { LANGUAGE_HEADER_KEY } from 'constants/language';

const AXIOS_TIMEOUT = process.env.NEXT_PUBLIC_TIMEOUT;

// Instantiate the interceptor
// createAuthRefreshInterceptor(axios, refreshAuthLogic);

function handleResponse(response) {
  return response.data;
}

function getHeaders(additionalHeaders = {}, language) {
  return {
    'Content-Type': 'application/json',
    ...additionalHeaders,
    ...(language && { [LANGUAGE_HEADER_KEY]: language }),
  };
}

function fullUrl(url) {
  if (/^(f|ht)tps?:\/\//i.test(url)) return url;

  // If called from the server-side (e.g., inside getServerSideProps or getStaticProps), the full backend server path is used.
  // If called from the client-side (browser), the API route path is used.
  if (typeof window === 'undefined') {
    return `${process.env.API_URL}${url}`;
  }

  return `/api${url}`;
}

const axiosSettings = ({ externalCall = false }) => ({
  skipAuthRefresh: externalCall,
  // withCredentials: !externalCall,
});

class Fetch {
  static async getJSON({ url, headers, externalCall = false, language }) {
    const fullPath = fullUrl(url);
    logInfoOrError({ method: 'GET', url: fullPath, context: 'Fetch' });

    return axios({
      method: 'get',
      url: fullPath,
      headers: getHeaders(headers, language),
      timeout: AXIOS_TIMEOUT,
      ...axiosSettings({ externalCall }),
    })
      .then(handleResponse)
      .catch((error) => {
        logInfoOrError({ method: 'GET', url: fullPath, error, context: 'Fetch' });
        throw error;
      });
  }

  static async postJSON({ url, data = {}, headers, language }) {
    const fullPath = fullUrl(url);
    logInfoOrError({ method: 'POST', url: fullPath, data, context: 'Fetch' });

    return axios({
      method: 'post',
      url: fullPath,
      data: JSON.stringify(data),
      headers: getHeaders(headers, language),
      timeout: AXIOS_TIMEOUT,
    })
      .then(handleResponse)
      .catch((error) => {
        logInfoOrError({ method: 'POST', url: fullPath, data, error, context: 'Fetch' });
        throw error;
      });
  }

  static async postFormData({ url, data, headers, externalCall = false }) {
    const fullPath = fullUrl(url);
    logInfoOrError({ method: 'POST', url: fullPath, context: 'Fetch' });

    return axios({
      method: 'post',
      url: fullPath,
      data,
      timeout: AXIOS_TIMEOUT,
      headers: { 'Content-Type': 'multipart/form-data', ...headers },
      ...axiosSettings({ externalCall }),
    })
      .then(handleResponse)
      .catch((error) => {
        logInfoOrError({ method: 'POST', url: fullPath, error, context: 'Fetch' });
        throw error;
      });
  }

  static async putJSON({ url, data, headers, externalCall = false, language }) {
    const fullPath = fullUrl(url);
    logInfoOrError({ method: 'PUT', url: fullPath, data, context: 'Fetch' });

    return axios({
      method: 'put',
      url: fullPath,
      data: JSON.stringify(data),
      headers: getHeaders(headers, language),
      timeout: AXIOS_TIMEOUT,
      ...axiosSettings({ externalCall }),
    })
      .then(handleResponse)
      .catch((error) => {
        logInfoOrError({ method: 'PUT', url: fullPath, data, error, context: 'Fetch' });
        throw error;
      });
  }

  static async putFileJSON({ url, file, headers }) {
    const fullPath = fullUrl(url);
    logInfoOrError({ method: 'PUT', url: fullPath, context: 'Fetch' });

    return axios({
      method: 'put',
      url: fullPath,
      data: file,
      timeout: AXIOS_TIMEOUT,
      ...headers,
    })
      .then(handleResponse)
      .catch((error) => {
        logInfoOrError({ method: 'PUT', url: fullPath, error, context: 'Fetch' });
        throw error;
      });
  }

  static async patchJSON({ url, data, headers, externalCall = false, language }) {
    const fullPath = fullUrl(url);
    logInfoOrError({ method: 'PATCH', url: fullPath, data, context: 'Fetch' });

    return axios({
      method: 'patch',
      url: fullPath,
      data: JSON.stringify(data),
      headers: getHeaders(headers, language),
      timeout: AXIOS_TIMEOUT,
      ...axiosSettings({ externalCall }),
    })
      .then(handleResponse)
      .catch((error) => {
        logInfoOrError({ method: 'PATCH', url: fullPath, data, error, context: 'Fetch' });
        throw error;
      });
  }

  static async deleteJSON({ url, headers, externalCall = false, language }) {
    const fullPath = fullUrl(url);
    logInfoOrError({ method: 'DELETE', url: fullPath, context: 'Fetch' });

    return axios({
      method: 'delete',
      url: fullPath,
      headers: getHeaders(headers, language),
      timeout: AXIOS_TIMEOUT,
      ...axiosSettings({ externalCall }),
    })
      .then(handleResponse)
      .catch((error) => {
        logInfoOrError({ method: 'DELETE', url: fullPath, error, context: 'Fetch' });
        throw error;
      });
  }
}

export default Fetch;

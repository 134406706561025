import React from 'react';

import ProfileImage from 'components/_images/ProfileImage';
import { AdminCompanyDto, OfficeType } from 'store/models/Company';

type CompanyMenuItemProps = {
  company: AdminCompanyDto;
};

const CompanyMenuItem = ({ company }: CompanyMenuItemProps) => {
  const showAddress =
    (!company?.officeType || company?.officeType === OfficeType.BRANCH || !company.duns) && company?.shortAddress;
  return (
    <div className="flex flex-row gap-x-2 items-center text-gray-600">
      <div className="w-8 h-8">
        <ProfileImage path={company.logo} alt={company.primaryName} width={32} height={32} />
      </div>

      <div className="flex flex-col w-40">
        <span
          data-testid={`${company.primaryName}_button`}
          className="self-center w-40 text-sm font-normal leading-5 text-left text-gray-700 truncate"
          title={company.primaryName}
        >
          {company.primaryName}
        </span>

        {showAddress && (
          <span
            className="self-center w-40 text-xs font-normal leading-none text-gray-500 truncate"
            title={company.fullAddress}
          >
            {company.fullAddress}
          </span>
        )}
      </div>

      {AdminCompanyDto.requiresAttention(company) && <div className="p-1.5 bg-blue-600 rounded-full" />}
    </div>
  );
};

export { CompanyMenuItem };
